<template>
<div class='outer'>
  <h1>Ten-Thousand Views of Mount Toro</h1>
  <h2>No. {{count}}: {{names[count]}} </h2>
  <div class='modalSurfaceBox' v-show='showModal' @click='unfocus'>
    <div class='modalSurface'>
      <vue-recaptcha
        v-show='showRecaptcha'
        v-if='showModal'
        ref="recaptcha"
        sitekey="6Lcep_odAAAAAFBZilkAswd17BEz07wyKprdFdAE"
        @verify='recievedResponse'
        />
      <div class='limitReachedText' v-show='showLimitReachedText'>
        Your limit of five views per day has been reached. Come back tomorrow!
      </div>
      <div class='selfDestructText' v-show='showSelfDestructText'>
        This view will self-destruct in one hour ...
      </div>

    </div>
  </div>
  <div class='modalSurfaceBox2' v-if='showModal2' @click='unfocus'>
    <div class='modalSurface2'>
      <h4>{{note}}</h4>
    </div>
  </div>
  <div class='modalScrim' v-if='showModal || showModal2' @click='unfocus'>
  </div>
  <div class='playButtonBox' v-if='!showModal && !showModal2'>
    <img class='icon' :src='iconUrls[iconIdx]' @click='togglePlay' />
  </div>
  <div class='infoIconBox' @click='toggleInfo'>
    <div class='infoIcon'>i</div>
  </div>

  <div class='downloadIconBox'>
    <div class='downloadIcon' @click='downloadFile'>
      <img :src='downloadIcon' class='downloadIconImg' ref='dlIcon' />
    </div>
  </div>
  <!-- <button @click='toggleModal'>Press me</button> -->
  <audio ref='audio' crossorigin='anonymous' :src='audioUrl'> </audio>
  <img class='sky' src="@/assets/sky4.png" />
  <img class='grass' src="@/assets/grass4.png" />
  <img class='bayMtn' src="@/assets/bayMtn4.png" />
</div>
</template>

<script>
import subtitles from '@/assets/subtitles.JSON';
import {
  Solver,
  hexToRgb,
  Color,
  getProperTextColor
} from '@/js/colorSolver.js';


const AudioContext = window.AudioContext || window.webkitAudioContext;
// import Sky from '@/assets/Sky.svg?inline';
// import grass from '@/assets/grass.svg?inline';
// import bayMtn from '@/assets/bayMtn.svg?inline';
import {
  VueRecaptcha
} from 'vue-recaptcha';
import seedrandom from 'seedrandom';
const axios = require('axios')

import playIcon from '@/assets/playIcon.png';
import pauseIcon from '@/assets/pauseIcon.png';
import downloadIcon from '@/assets/downloadIcon_.png';

import Cookies from 'js-cookie';

const download = (url, downloadFileName) => {
  const a = document.createElement('a')
  a.href = url;
  a.download = downloadFileName;
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
};

export default {
  name: 'AudioPlayback',
  props: {
    msg: String
  },
  data() {
    return {
      showRecaptcha: true,
      showLimitReachedText: false,
      showSelfDestructText: false,
      cookies: Cookies,
      playing: false,
      grassRot: '110.1deg',
      bayMtnRot: '30.77deg',
      skyRot: '17.1deg',
      count: 0,
      init: true,
      iconIdx: 0,
      names: subtitles,
      showModal: false,
      showModal2: false,
      iconUrls: [playIcon, pauseIcon],
      downloadIcon: downloadIcon,
      textColor: 'black',
      url: window.location.href,
      // url: 'https://tenthousandviews.com/',
      note: "\nA warning, please take note, ye scoundrels! This website is a \
portal to an ephemeral temporal form, a 'musical composition' written \
as a trick, perhaps, by some fetid imp, with a limited number of unique \
'views' -- a mere 10,000 instances, each an expression of \
the artificial idiom formally 'notated' in the code that serves as its \
'score'. \n\n\
Each 'vista' is unique, each blessed to exist \
for just one auspicious encounter with a lucky soul -- a friendly \
listener, I pray! Once heard, each is destroyed, never to \
be heard again. So if you are inclined to listen, listen well. \
Be advised that each 'viewing' lasts somewhere between one and \
two thirds of an hour. Best not to know for sure, lest ye grind your teeth \
staring off at the clock waiting for an 'ending'. \n\n\
I must also warn you, poor devils, that the harmonic activity herein \
contained is of a strong pungency not usually on offer in such an \
overflowing grandiosity! Microtonal, yes, and free-floating from \
pitch-shape to pitch-shape in harmonic space. One might even accuse this activity \
of being 'out of tune', hah hah hah! (As if wine were grapes gone rotten!) \
Better to say that the pitch collections \
create sensations which a person can perceive as unique and \
learn to associate with other such sensations, building up an associative network of harmonic experience \
much more finely grained and diverse than the networks generated from exposure to \
the fixed-pitch systems of the so-called 'instrumental age' ... \n\n\
But I've said too much already, and music does have a way of speaking for itself, \
after all, oblivious of all this prattle. \n\n\
Jon Myers \n\
Santa Cruz, CA, Dec. '21\n\n\n",
    }
  },
  mounted() {
    this.getCount()
    this.ac = new AudioContext();
    this.audioElement = this.$refs.audio;
    this.src = this.ac.createMediaElementSource(this.audioElement);
    this.gainNode = this.ac.createGain();
    this.src.connect(this.gainNode).connect(this.ac.destination);
  },
  computed: {
    audioUrl() {
      this.getCount();
      return this.url + `audio/${this.count}.wav`
    }
  },

  components: {
    VueRecaptcha
  },
  methods: {
    
    togglePlay() {
      if (this.init) {
        this.showModal = true;
      } else if (this.playing) {
        this.toggleAudio();
        this.iconIdx = 0
      } else {
        this.toggleAudio();
        this.iconIdx = 1
      }
    },

    toggleAudio() {
      if (this.ac.state === 'suspended') {
        this.ac.resume();
      }

      if (this.playing === false) {
        this.audioElement.play();
        this.playing = true
      } else if (this.playing === true) {
        this.audioElement.pause();
        this.playing = false
      }
    },


    randomizeRots(count) {
      const gen = new seedrandom(String(count))
      this.grassRot = String(gen() * 360) + 'deg';
      this.skyRot = String(gen() * 360) + 'deg';
      this.bayMtnRot = String(gen() * 360) + 'deg';
      this.shadowColor = '#' + (0x1000000 + gen() * 0xffffff).toString(16).substr(1, 6);
      const shadowX = 20 * gen() - 10;
      const shadowY = 20 * gen() - 10;
      const offsetX = -1 * shadowX * 0.4;
      const offsetY = -1 * shadowY * 0.4;
      const bigShadowX = shadowX * 1.6;
      const bigShadowY = shadowY * 1.6;
      this.shadowX = shadowX + 'px';
      this.shadowY = shadowY + 'px';
      this.offsetX = offsetX + 'px';
      this.offsetY = offsetY + 'px';
      this.bigShadowX = bigShadowX + 'px';
      this.bigShadowY = bigShadowY + 'px';
    },

    getCount() {
      axios
        .get(this.url + 'count')
        .catch(error => console.log(error))
        .then(res => res.data)

        .then(ct => {
          this.count = ct;
          this.randomizeRots(ct)
        })
        .then(() => {
          this.setDownloadIconColor();
          this.setTextColor();
        })
    },

    setTextColor() {
      this.textColor = getProperTextColor(this.shadowColor)
    },

    recievedResponse(e) {

      const request = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ data: e })
      };
      fetch(this.url + 'verifyRecaptcha', request)
        .then(res => res.json())
        .then(data => {
          if (data.success) this.passedTest()
        })
        .catch(error => console.log(error))
    },


    passedTest() {

      // plus 24 hours
      // const expiryTime = new Date(+new Date() + 60 * 60 * 24 * 1000);
      // document.cookie = ""

      if (!Cookies.get('numViews')) {
        const currentDate = new Date();
        const expiryTime = new Date(currentDate.getTime() + 1000 * 60 * 60 * 24);
        Cookies.set('numViews', '1', { expires: expiryTime })
        Cookies.set('expiryTime', expiryTime, { expires: expiryTime })
      } else {
        const cur = Number(Cookies.get('numViews'));
        const expiryTime = new Date(Cookies.get('expiryTime'));
        Cookies.set('numViews', (cur+1).toString(), { expires: expiryTime })
      }

      if (Number(Cookies.get('numViews')) > 5) {
        this.showLimitReachedText = true;
        this.showRecaptcha = false;
      } else {
        this.showSelfDestructText = true;
        this.showRecaptcha = false;
        setTimeout(() => {
          this.showModal = false;
          axios
            .put(this.url + 'setCount')
            .catch((error) => console.log(error))
          this.init = false;
        }, 3000)

      }
    },

    toggleInfo() {
      this.showModal2 = true;
    },

    unfocus() {
      this.showModal2 = false;
      this.showModal = false;

    },

    setDownloadIconColor() {
      const rgb = hexToRgb(this.shadowColor);
      const color = new Color(rgb[0], rgb[1], rgb[2]);
      const solver = new Solver(color);
      const result = solver.solve();

      this.$refs.dlIcon.style = result.filter;

    },

    downloadFile() {
      if (this.init) {
        this.showModal = true
      } else {
        const url = this.url + 'audio/' + this.count + '.wav';
        const fileName = `Number ${this.count} — ${this.names[this.count]}`
        download(url, fileName)
      }

    }

  }
}
</script>

<style scoped>
.grass {
  filter: hue-rotate(v-bind(grassRot));
}

.sky {
  filter: hue-rotate(v-bind(skyRot));
}

.bayMtn {
  filter: hue-rotate(v-bind(bayMtnRot));
}

h1 {
  color: black;
  font-size: 60px;
  margin: 0px;
  padding: 20px;
}

h2 {
  color: black;
  margin: 0px;
  padding: 10px;
  font-style: italic;
}

img {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  object-fit: cover
}


.modalScrim {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.modalSurfaceBox {
  width: 100%;
  height: 60%;
  max-height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  overflow: hidden;
}

.modalSurface {
  width: 334px;
  height: 108px;
  background-color: v-bind(shadowColor);
  display: flex;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  overflow: auto;
  border-radius: 2px;
}

.modalSurfaceBox2 {

  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: top;
  z-index: 3;
  overflow: hidden;
}

.modalSurface2 {
  width: 80vw;
  height: 80%;
  background-color: v-bind(shadowColor);
  display: flex;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  overflow: auto;
}

h4 {
  white-space: pre-wrap;
  color: v-bind(textColor);
  width: 90%;
  height: calc(100% - 40px);
  font-family: "Lucida Console", "Courier New", monospace;
  font-size: 18px;
  text-align: left;
}

.playButtonBox {
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

img.icon {
  position: relative;
  z-index: 1;
  width: 200px;
  height: 200px;
  top: 20%;
  cursor: pointer;
  filter: brightness(0) drop-shadow(v-bind(shadowX) v-bind(shadowY) v-bind(shadowColor));
  transition: 0.06s;
}


img.icon:hover {
  filter: brightness(0) drop-shadow(v-bind(bigShadowX) v-bind(bigShadowY) v-bind(shadowColor));
  transform: translate(v-bind(offsetX), v-bind(offsetY));
}

.outer {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

}

.infoIconBox {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 80px;
  height: 80px;
}

.infoIcon {
  width: 77px;
  height: 77px;
  font-size: 64px;
  color: v-bind(shadowColor);
  background-color: black;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  filter: drop-shadow(v-bind(shadowX) v-bind(shadowY) v-bind(shadowColor));
  transition: 0.06s;
}

.infoIcon:hover {
  filter: drop-shadow(v-bind(bigShadowX) v-bind(bigShadowY) v-bind(shadowColor));
  transform: translate(v-bind(offsetX), v-bind(offsetY));
}

.downloadIconBox {
  position: fixed;
  left: 5px;
  bottom: 10px;
  width: 80px;
  height: 80px;
}

.downloadIcon {
  width: 77px;
  height: 77px;
  font-size: 64px;
  color: v-bind(shadowColor);
  background-color: black;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  filter: drop-shadow(v-bind(shadowX) v-bind(shadowY) v-bind(shadowColor));
  transition: 0.06s;
}

.downloadIcon:hover {
  filter: drop-shadow(v-bind(bigShadowX) v-bind(bigShadowY) v-bind(shadowColor));
  transform: translate(v-bind(offsetX), v-bind(offsetY));
}

.downloadIconImg {
  position: relative;
  color: v-bind(shadowColor);
  width: 50px;
  height: 50px;
}

.limitReachedText {
  color: v-bind(textColor);
  font-family: "Lucida Console", "Courier New", monospace;
  font-size: 18px;
  font-weight: bolder;
}

.selfDestructText {
  color: v-bind(textColor);
  font-family: "Lucida Console", "Courier New", monospace;
  font-size: 18px;
  font-weight: bolder;
}

@media (max-width: 1030px) {
  h1 {
    font-size: 45px
  }
  
  h2 {
    font-size: 20px;
  }
}

@media (max-width: 785px) {
  h1 {
    font-size: 35px
  }
  
  h2 {
    font-size: 16px
  }
}
</style>
