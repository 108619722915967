<template>
  <AudioPlayback />
</template>

<script>
import AudioPlayback from './components/AudioPlayback.vue';

document.title = "Ten-Thousand Views of Mount Toro"

export default {
  name: 'App',
  components: {
    AudioPlayback,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}

body {
  margin: 0px;
  overflow: hidden;
}
</style>
